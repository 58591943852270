import { render, staticRenderFns } from "./structureTypeHelp.vue?vue&type=template&id=324ef368&"
import script from "./structureTypeHelp.vue?vue&type=script&lang=js&"
export * from "./structureTypeHelp.vue?vue&type=script&lang=js&"
import style0 from "./structureTypeHelp.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports