<template>
	<section class="askPrestationPopin">
		<v-card
		flat
		outlined
		class="newCoringsValidationPopIn__card"
		id="modale"
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>Effectuer des analyses amiante / HAP ?</h3>
			</v-card-title>

			<div>
				Votre campagne comprend-elle des analyse amiante / HAP ?
			</div>
  
			<v-card-actions class="tw-flex tw-justify-around tw-w-full">
				<ButtonSlot @click="noButton">
					Non
				</ButtonSlot>

				<ButtonSlot @click="yesButton">
					Oui
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>
  
<script>
  
export default {
	name: "AskPrestationPopin",
	data(){
	  return {
	  };
	},
	methods: {
	  noButton(){
			this.$emit("noButton", false);
	  },
	  yesButton(){
			this.$emit("yesButton", true);
	  }
	},
	mounted(){
	  this.$root.$on("closeCurrent", this.closePopin);
	}
};
</script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/_extends.scss";
  
  .askPrestationPopin {
	@extend %popinView;
	.v-card {
	  width: 40%;
	  text-align: center;
	  padding: 20px 20px;
	  max-height: 90%;
	  overflow: auto;
	}
  
	&__card p {
	  text-align: left !important;
	  margin: 0 0 20px 0 !important;
	}
  }
  </style>
  
