<template>
	<div
	class="draggable"
	:style="{ top: `${position.top}px`, left: `${position.left}px`, width: `${size.width}px`, height: `${size.height}px`, position: 'absolute' }"
	@mousedown="onMouseDown"
	id="dragg"
	>
		<Resize
		@resize="onResize"
		class="resize-box tw-p-[10px]"
		style="background:white; border: 1px solid black; border-radius: 4px;"
		>
			<div class="tw-h-full tw-overflow-y-auto">
				<div class="tw-flex tw-row">
					<ButtonSlot
					_icon="mdi-close"
					_theme="light-gray"
					class="tw-left-0 tw-mb-[10px]"
					@click="close()"
					/>
					<div class="tw-flex tw-justify-center tw-w-full">
						Règles pour établir la note d'une couche traitée IEM
					</div>
				</div>
				<div class="tw-flex tw-justify-center">
					<table class="tw-min-w-full tw-bg-white tw-border-collapse tw-border tw-border-gray-400 tw-border-solid">
						<thead>
							<tr>
								<th class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									Indice
								</th>
								<th class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									Indice hydraulique
								</th>
								<th class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									Couche bitumineuse
								</th>
								<th class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									Observations - Conclusions
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									0
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									TTBO
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_TTBO.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									CTBO
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_CTBO.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									Carotte intacte<br/>Découpe franche <br/><span class="tw-underline">
										Très bonne tenue
									</span>
								</td>
							</tr>
							<tr>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									1
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									TBON
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_TBON.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									CBON
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_CBON.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									<span class="tw-font-bold">
										Carotte intacte<br/>Découpe avec quelques arrachements <br/>
									</span><span class="tw-underline">
										Bonne tenue
									</span>
								</td>
							</tr>
							<tr>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									2
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									TMED
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_TMED.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									CMED
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_CMED.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									<span class="tw-font-bold">
										Carotte intacte<br/>Découpe avec nombreux arrachements
									</span><br/>Sur couche bitumineuse, rupture possible à la main, aspect rupture "terne" de matériaux oxydés dans la masse <br/><span class="tw-underline">
										Tenue médiocre
									</span>
								</td>
							</tr>
							<tr>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									3
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									FEUIL
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_FEUIL.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center"/>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									Carotte en deux parties<br/>Découpe franche, ou avec arrachements au niveau de la rupture <br/>Les deux faces de la rupture sont lisses<br/><span class="tw-underline">
										Feuilletage
									</span>
								</td>
							</tr>
							<tr>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									4
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									FRAC
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_FRAC.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center"/>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									<span class="tw-font-bold">
										Couche présentant au carottage des fragments engrenés<br/>Découpe franche, ou avec quelques arrachements<br/>Eventuellement traces de fines sur les lèvres
									</span><br/><span class="tw-underline">
										Fracturation sous trafic
									</span>
								</td>
							</tr>
							<tr>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									5
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									TFAI
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_TFAI.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									CFAI
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_CFAI.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									<span class="tw-font-bold">
										Couche détruite partiellement ou en totalité au carottage
									</span><br/>Découpe avec arrachements<br/>Extraction de fragments non engrenés (allure de rognons de GH prise) et de granulats<br/><span class="tw-underline">
										Fracturation au carottage<br/>Tenue faible
									</span>
								</td>
							</tr>
							<tr>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									6
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									MADI
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_MADI-11.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									MADI
									<div class="tw-flex tw-items-center tw-justify-center">
										<v-img
										src="@/assets/images/coring/helpModal/DIR/MA22SKEM_DIR-carottes_MADI-12.png"
										class="tw-w-[50px]"
										/>
									</div>
								</td>
								<td class="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-text-center">
									<span class="tw-font-bold">
										Couche détruite au carottage<br/>Découpe avec formation de cavités, granulats
									</span> se descellant souvent à la main<br/>Matériaux totalement dissociés, extraction de granulats et de fines<br/><span class="tw-underline">
										Matériaux désagrégés en place ou a résistance très faible
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="vue-resizer-handle vue-resizer-handle-top-left"/>
			<div class="vue-resizer-handle vue-resizer-handle-top-right"/>
			<div class="vue-resizer-handle vue-resizer-handle-bottom-left"/>
			<div class="vue-resizer-handle vue-resizer-handle-bottom-right"/>
		</Resize>
	</div>
</template>
  
<script>
import {Resize} from "vue-resizer";

export default {
	name: "App",
	components: {
		Resize,
	},
	data(){
		return {
			position: {top: 100, left: 100},
			size: {width: 800, height: 800},
			isDragging: false,
			dragStart: {top: 0, left: 0},
		};
	},
	methods: {
		onResize(event){
			this.size.width = event.width;
			this.size.height = event.height;
			console.log(`Resized to ${event.width}x${event.height}`);
		},
		onMouseDown(event){
			if(event.target.classList.contains("resize-box")){
				return;
			}
			this.isDragging = true;
			this.dragStart = {
				top: event.clientY - this.position.top,
				left: event.clientX - this.position.left,
			};
			document.addEventListener("mousemove", this.onMouseMove);
			document.addEventListener("mouseup", this.onMouseUp);
		},
		onMouseMove(event){
			if(this.isDragging){
				this.position.top = event.clientY - this.dragStart.top;
				this.position.left = event.clientX - this.dragStart.left;
			}
		},
		onMouseUp(){
			this.isDragging = false;
			document.removeEventListener("mousemove", this.onMouseMove);
			document.removeEventListener("mouseup", this.onMouseUp);
		},
		close(){
			this.$emit("close");
		},
	},
};
</script>
  
<style>
.draggable-container {
  cursor: move;
  display: inline-block; /* S'ajuste automatiquement à la taille de son contenu */
  border: 1px solid #3f51b5;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.resize-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}

.draggable-content {
  width: 100%;
  height: 100%;
}

.vue-resizer-handle {
  background: #3f51b5;
  position: absolute;
  z-index: 1;
}

.vue-resizer-handle-top {
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: n-resize;
}

.vue-resizer-handle-right {
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: e-resize;
}

.vue-resizer-handle-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: s-resize;
}

.vue-resizer-handle-left {
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  cursor: w-resize;
}
</style>
