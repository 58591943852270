<template>
	<div
	class="draggable"
	:style="{ top: `${position.top}px`, left: `${position.left}px`, width: `${size.width}px`, height: `${size.height}px`, position: 'absolute' }"
	@mousedown="onMouseDown"
	id="dragg"
	>
		<Resize
		@resize="onResize"
		class="resize-box tw-p-[10px]"
		style="background:white; border: 1px solid black; border-radius: 4px; min-width: 600px; min-height: 600px"
		>
			<div class="tw-h-full tw-overflow-x-hidden">
				<div class="tw-flex tw-row">
					<ButtonSlot
					_icon="mdi-close"
					_theme="light-gray"
					class="tw-left-0 tw-mb-[10px]"
					@click="close()"
					/>
					<h3 class="tw-flex tw-justify-center tw-w-full">
						Type de structure
					</h3>
				</div>
				<div class="tw-flex tw-justify-center tw-w-full tw-flex-col">
					<div
					v-for="pageNumber in numPages"
					:key="pageNumber"
					>
						<pdf
						:src="pdfFile"
						:page="pageNumber"
						/>
					</div>
				</div>
			</div>
			<div class="vue-resizer-handle vue-resizer-handle-top-left"/>
			<div class="vue-resizer-handle vue-resizer-handle-top-right"/>
			<div class="vue-resizer-handle vue-resizer-handle-bottom-left"/>
			<div class="vue-resizer-handle vue-resizer-handle-bottom-right"/>
		</Resize>
	</div>
</template>
  
<script>
import {Resize} from "vue-resizer";
import pdf from "vue-pdf";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  
export default {
	name: "StructureTypeHelp",
	components: {
		Resize, pdf
	},
	data(){
	  return {
			position: {top: 100, left: 100},
			size: {width: 800, height: 700},
			isDragging: false,
			dragStart: {top: 0, left: 0},
			pdfFile: false,
			numPages: null
	  };
	},
	methods: {
	  onResize(event){
			this.size.width = event.width;
			this.size.height = event.height;
			console.log(`Resized to ${event.width}x${event.height}`);
	  },
	  onMouseDown(event){
			if(event.target.classList.contains("resize-box")){
		  return;
			}
			this.isDragging = true;
			this.dragStart = {
		  top: event.clientY - this.position.top,
		  left: event.clientX - this.position.left
			};
			document.addEventListener("mousemove", this.onMouseMove);
			document.addEventListener("mouseup", this.onMouseUp);
	  },
	  onMouseMove(event){
			if(this.isDragging){
		  this.position.top = event.clientY - this.dragStart.top;
		  this.position.left = event.clientX - this.dragStart.left;
			}
	  },
	  onMouseUp(){
			this.isDragging = false;
			document.removeEventListener("mousemove", this.onMouseMove);
			document.removeEventListener("mouseup", this.onMouseUp);
	  },
	  close(){
			this.$emit("close");
	  },
	  async getDocumentContent(){
			this.pdfFile = URL.createObjectURL(await this.$api.documents.download("6e42295a-a6e4-43b8-84d9-d37c5d9db7b3/pdfHelp/Type_Structure.pdf"));
			const pdfDocument = await pdfjsLib.getDocument(this.pdfFile).promise;
       		this.numPages = pdfDocument.numPages;
	  }
	},
	mounted(){
		this.getDocumentContent();
	}
};
</script>
  
<style>
.draggable-container {
  cursor: move;
  display: inline-block; /* S'ajuste automatiquement à la taille de son contenu */
  border: 1px solid #3f51b5;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.resize-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}

.draggable-content {
  width: 100%;
  height: 100%;
}

.vue-resizer-handle {
  background: #3f51b5;
  position: absolute;
  z-index: 1;
}

.vue-resizer-handle-top {
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: n-resize;
}

.vue-resizer-handle-right {
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: e-resize;
}

.vue-resizer-handle-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: s-resize;
}

.vue-resizer-handle-left {
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  cursor: w-resize;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
}
canvas {
  width: 100%;
  height: auto;
}
</style>
