<template>
	<div class="tw-flex tw-flex-col">
		<div class="tw-flex tw-flex-row tw-gap-[10px] tw-p-[10px]">
			<v-select
			:items="['Sens 1', 'Sens 2']"
			v-model="filterDirection"
			label="Choix du sens"
			outlined
			class="tw-w-[300px] tw-flex"
			hide-details
			dense
			@change="handleFilter()"
			clearable
			/>
  
			<v-select
			:items="filterDirection ? transformLanes(filterDirection === 'Sens 1' ? uniqueLanes.direction1 : uniqueLanes.direction2) : []"
			v-model="filterLane"
			label="Voie"
			outlined
			class="tw-w-[300px] tw-flex"
			hide-details
			dense
			clearable
			/>
		</div>
  
		<!-- Affichage des cores filtrés -->
		<div class="tw-h-full tw-p-[10px] tw-flex tw-gap-[10px] tw-flex-row tw-w-full tw-overflow-x-auto">
			<div
			v-for="core in filteredCores"
			:key="core.id"
			>
				<div class="tw-rounded-[4px] tw-border-solid tw-border-[1px] tw-w-[200px] tw-justify-center tw-align-middle">
					<h3 class="tw-flex tw-justify-center">
						{{ core.displayNamePrefix ? core.displayNamePrefix.name + core.displayName : core.businessId }}
					</h3>
					<span class="tw-flex tw-justify-center">
						{{ core.road ? core.road.name : '' }}
					</span>
					<span class="tw-flex tw-justify-center">
						{{ core.lane ? core.lane : 'Sans voie' }}
					</span>
					<span class="tw-flex tw-justify-center">
						{{ core.lateralPosition }}
					</span>
					<span class="tw-flex tw-justify-center">
						Sens {{ core.direction }}
					</span>
					<span class="tw-flex tw-justify-center">
						PR {{ core.landmark }} + {{ core.abscissa }}
					</span>
					<div
					v-for="layer in core.layersAndInterfaces"
					:style="getStyleForLayer(layer)"
					class="tw-border-[1px] tw-flex tw-border-solid tw-flex-col tw-overflow-y-auto"
					>
						<div v-if="layer.type === 'layer'">
							<span
							v-if="!layer.layerName"
							class="tw-font-bold tw-justify-center tw-flex"
							>
								Couche {{ layer.number }}
							</span>
							<span
							v-if="layer.layerName"
							class="tw-font-bold tw-justify-center tw-flex"
							>
								{{ layer.layerName.name }}
							</span>
							<span class="tw-font-bold tw-justify-center tw-flex">
								{{ layer.material.initialism }} {{ layer.granulometry ? layer.granulometry.name : '' }}
							</span>
							<span class="tw-font-bold tw-justify-center tw-flex">
								{{ $t(layer.state) }}
							</span>
							<span
							v-if="layer.note"
							class="tw-font-bold tw-justify-center tw-flex"
							>
								{{ layer.note }}
							</span>
							<span class="tw-font-bold tw-justify-center tw-flex">
								{{ layer.thickness }} mm
							</span>
						</div>
						<div
						v-else
						>
							<span class="tw-font-bold tw-justify-center tw-flex">
								{{ $t(layer.state) }}
							</span>
							<span class="tw-font-bold tw-justify-center tw-flex">
								{{ layer.note }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "CoreTable",
	props: {
		cores: {
			type: Array,
			default: true
		}
	},
	data(){
		return {
			filteredCores: this.cores,
			filterDirection: null,
			filterLane: null,
			uniqueLanes: []
		};
	},
	methods: {
		getStyleForLayer(layer){
			let height = 50;
			if(layer.type === "layer"){
				height = layer.thickness * 2;
				return `height: ${height}px; min-height : 80px; background-color: lightgrey`;
			}
			else {
				return `height: ${height}px; min-height : 50px;`;
			}
			
		},
		handleFilter(){
			this.filterLane = null;
			this.filteredCores = this.cores.filter(core => {
				let direction = null;
				if(this.filterDirection !== null){
					direction = this.filterDirection === "Sens 1" ? 1 : 2;
				}
				const matchesDirection = this.filterDirection ? core.direction === direction : true;
				
				const matchesLane = this.filterLane ? core.lane === this.filterLane : true;

				return matchesDirection && matchesLane;
			});
		},
		transformLanes(lanes){
			return lanes.map(lane => lane === null ? "Sans voie" : lane);
		},
		extractUniqueLanes(){
			const lanesByDirection = {
				direction1: new Set(),
				direction2: new Set()
			};
    
			this.cores.forEach(core => {
				if(core.direction === 1){
					lanesByDirection.direction1.add(core.lane);
				}
				else if(core.direction === 2){
					lanesByDirection.direction2.add(core.lane);
				}
			});

			return {
				direction1: Array.from(lanesByDirection.direction1),
				direction2: Array.from(lanesByDirection.direction2)
			};
		},
	},
	async mounted(){
		this.uniqueLanes = this.extractUniqueLanes();
	}
};
</script>


<style lang="scss">
</style>
